export class ChatHistoryObject {
    limit = 0;
    page = 0;
    results: chatHistory[] = [new chatHistory()];
    totalPages = 0;
    totalResults = 0;
}

export class chatHistory {
    createdAt = '';
    title = '';
    updatedAt = '';
    user = '';
    id = '';
    description = '';
    listening= false;
    refId ='';
}

export class messages {
    limit = 0;
    page = 0;
    results: message[] = [new message()];
    totalPages = 0;
    totalResults = 0;
    type = 'NEW' || 'OWNER' || 'FOLLOWER';
    chatId = '';
}

export class message {
    chat = '';
    content = '';
    createdAt = '';
    id = '';
    media = [];
    role = '';
    updatedAt = '';
    questionRef = '';
    listening= false;
    refId ='';
    sources:AnswerSource[]=[];
}

export interface chat{
    answer: string, 
    question: string, 
    loading: boolean,
    listening?: boolean,
    id: string;
    refId?: string;
    sources?:AnswerSource[] | any;
}
export class AskQuestionModel{
    chatId='';
    clientDateTime = new Date();
    textQuestion= '';
    audioQuestion ='';
    mediaKeys: any[] = [];
    regenerate = false;
    processType? = '';
}
export class ChatResponseModel{
    clientSocketId= '';
    data='';
    event='';
    error = '';
}
export class AnswerSource{
    _id='';
    date='';
    infoTitle='';
    sourceTitle='';
    url='';
}